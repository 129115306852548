import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const RouteSpecificMeta = ({ title, description, keywords, canonical }) => {
  const location = useLocation();

  useEffect(() => {
    // console.log('RouteSpecificMeta updated:', location.pathname);
    // console.log('Title:', title);
    // console.log('Description:', description);
    // console.log('Keywords:', keywords);
    // console.log('Canonical:', canonical);
  }, [location, title, description, keywords, canonical]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={keywords} />
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default RouteSpecificMeta;


// const RouteSpecificMeta = ({title, description, keywords, canonical}) => {
//   return (
//     <>
//       <Helmet>
//         <title>{title}</title>
//         <meta name="description" content={description} />
//         <meta name="keywords" content={keywords} />
//         {canonical && <link rel="canonical" href={canonical} />}
//         {image && <meta property="og:image" content={image} />}
//         <meta property="og:title" content={title} />
//         <meta property="og:description" content={description} />
//         <meta property="og:type" content="website" />
//         {image && <meta name="twitter:card" content="summary_large_image" />}
//         <meta name="twitter:title" content={title} />
//         <meta name="twitter:description" content={description} />
//         {image && <meta name="twitter:image" content={image} />}
//       </Helmet>
//     </>
//   )
// }
// export default RouteSpecificMeta;