import ImagesUrl from "../../assets/images/Index";

export const HomeProductData = [
    {
        title:"Fuel Growth and Profitability with <span className='text-magenta'> Qkrbiz!</span>", 
        desc:"Simplify complexity to drive profitable growth for your business.",
        link:"/signup",
        imgUrl:ImagesUrl.HomeBottomImage,
          // AppMenue:[
        //     {
        //         id:1,
        //         urls:"/hrms-software",
        //         productClass:"qkrhr",
        //         title:"QkrHR", 
        //         desc:"Help your team achieve their full potential",
        //     },
        //     {
        //         id:2,
        //         urls:"/qkrvisit",
        //         productClass:"qkrvisit",
        //         title:"Qkrvisit",
        //         desc:"Field force automation for sales, service and project team",
        //     },
        //     {
        //         id:3,
        //         urls:"/qkrexpense",
        //         productClass:"qkrexpense",
        //         title:"Qkrexpense",
        //         desc:"Advances, Travel & Expenses",
        //     },
        //     {
        //         id:4, 
        //         urls:"/qkrdesk",
        //         productClass:"qkrdesk",
        //         title:"Qkrdesk",
        //         desc:"Helpdesk software connecting end users of clients",
        //     },
        //     {
        //         id:5,
        //         urls:"/qkrsales",
        //         productClass:"qkrsales",
        //         title:"Qkrsales",
        //         desc:"Order to cash management for government projects",
        //     },
        //     {
        //         id:6,
        //         urls:"/qkrtenders",
        //         productClass:"qkrtenders",
        //         title:"Qkrtenders",
        //         desc:"Manage Tender bidding and Deposits",
        //     },
        //     {
        //         id:7,
        //         urls:"/qkrteams",
        //         productClass:"qkrteams",
        //         title:"Qkrteams",
        //         desc:"Task Management",
        //     },
        //     {
        //         id:8,
        //         urls:"/qkrprojects",
        //         productClass:"qkrprojects",
        //         title:"Qkrprojects",
        //         desc:"Collaborative Work Management",
        //     }
        // ]
    }
];

export const EffortlessUserData = [
    {
        title:"Effortless User Experience", 
        desc:"Create a culture of <span className='text-magenta'> accountability </span> with actions and information at your fingertips.",
        EffortlessList:[
            {
                id:1,
                imgUrl:ImagesUrl.EffortlessExperience.simplifiedBox,
                title:"Simplified In-box", 
                subTitle:'Missing the simplicity of an "In-box"',
                desc:"Complete tasks quickly with a single in-box. Carefully crafted pages provide a comprehensive overview of all pertinent information with deep dive connections.",
            },
            {
                id:2,
                imgUrl:ImagesUrl.EffortlessExperience.familiarSpreadsheet,
                title:"Familiar Spreadsheet Features", 
                subTitle:"Seeking to minimize training time?",
                desc:"Start working quickly with a familiar spreadsheet like user-friendly interface. Reduce the learning curve with access to frequently used spreadsheet features on every page.",
            },
            {
                id:3,
                imgUrl:ImagesUrl.EffortlessExperience.clickAnywhere,
                title:"Click From Anywhere", 
                subTitle:"Need quick access everywhere?",
                desc:"Never search for menus, with action menus available across all the pages on a single click.",
            },
            {
                id:4,
                imgUrl:ImagesUrl.EffortlessExperience.commandControl, 
                title:"Command and Control Center", 
                subTitle:"Need Actionable Intelligence?",
                desc:"Dashboards that provide all deviations and pending updates at one place, making sure that no needed actions are overlooked.",
            },
            {
                id:5,
                imgUrl:ImagesUrl.EffortlessExperience.employeeSelf, 
                title:"Employee Self Service", 
                subTitle:"Looking for a unified interface?",
                desc:"No more navigating to separate pages, with all pages designed to satisfy the needs of all users, be they managers, administrators, HR team, or employees with no reportees.",
            },
            {
                id:6,
                imgUrl:ImagesUrl.EffortlessExperience.customViews, 
                title:"Custom Views", 
                subTitle:"Require tailored views to meet your job needs?",
                desc:"Create an unlimited number of Custom Table Views, Kanban Boards, Card Views, Ordered Lists & Pivot Tables.",
            },
           
        ]
    }
];

export const SecureAccData = [
    {
        title:"Secure and Accountable <span className='text-magenta'>Process</span>", 
        desc:"Qkrbiz ensures the security of the product and your information, at all times, with the Trustworthy Computing approach.",
        SecureAccList:[
            {
                id:1,
                imgUrl:ImagesUrl.accountable.SecureAccess,
                title:"Secure Access", 
                desc:"Protect your data with secure access and encryption through SSL/TLS.",
                cls:"text-right",
            },
            {
                id:2,
                imgUrl:ImagesUrl.accountable.RoleBased,
                title:"Role Based Access", 
                desc:"Ensure efficient and accountable access with role-based restrictions for employees in different departments or profiles.",
                cls:"text-left",
            },
            {
                id:3,
                imgUrl:ImagesUrl.accountable.ControlOver,
                title:"Control Over the Process", 
                desc:"Maintain control over your entire process by recording every step, eliminating ambiguities and avoiding blame games.",
                cls:"text-right",
            },
            {
                id:4,
                imgUrl:ImagesUrl.accountable.DataLevel,
                title:"Data Level Security", 
                desc:"Enforce data level security through organizational hierarchy or role-defined restrictions.",
                cls:"text-left",
            },
           
        ]
    }
];

