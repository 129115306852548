import React, { Fragment } from 'react';
import "./Header.scss";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { useState, useEffect } from 'react';
import { HeaderMenuData} from './HeaderMenuData';

function Header() {
  
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [navbarClass, setNavbarClass] = useState('');
    const [isSectionVisible, setIsSectionVisible] = useState('');

    const handleNavbarToggle = () => {
        setIsNavExpanded(!isNavExpanded);
        setNavbarClass(isNavExpanded && '');
        setIsSectionVisible(isNavExpanded ? '' : 'backdrop');
    };
    const handleEscapeKey = () => {
        setIsNavExpanded('');
        setNavbarClass('');
        setIsSectionVisible('');
    };
    const navbarToggles = () => {
        setIsNavExpanded(!isNavExpanded);
        setNavbarClass(isNavExpanded && '');
        setIsSectionVisible(isNavExpanded ? '' : 'backdrop');
    }
    
    // const [loggedIn, setLoggedIn] = useState(false);
    // const handleLogin = (status) => {
    //   setLoggedIn(status);
    // };
    // const handleLogout=()=>{
    //   setLoggedIn(false);
    // }
  
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && isNavExpanded) {
        setIsNavExpanded(false);
        setNavbarClass('');
        setIsSectionVisible('');
      }
    };
    document.addEventListener('keyup', handleEscapeKey);
    return () => {
      document.removeEventListener('keyup', handleEscapeKey);
    };
  }, [isNavExpanded]);

  return (
    <>
      <Navbar className="desktopShow" expand="lg" expanded={isNavExpanded} onToggle={handleNavbarToggle}>
          <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Link to="/" className="navbar-brand qkr-biz">krbiz</Link>
              <Navbar.Collapse id="basic-navbar-nav" className={navbarClass}>
                  <div className="nav-close">
                      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={navbarToggles}/>
                      <Link to="/" className="navbar-brand qkr-biz" onClick={handleEscapeKey}>krbiz</Link>
                  </div>
                  <Nav className="ms-auto">
                      {/* { loggedIn ? (
                          <>
                            <LinkContainer to="/" onClick={handleEscapeKey}>
                              <Nav.Link>Product List</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/" onClick={handleLogout}>
                              <Nav.Link>Logout</Nav.Link>
                            </LinkContainer>
                          </>
                          ) :(
                          <>
                            <LinkContainer to="/logins" onClick={handleLogin} style={{display:'none'}}>
                              <Nav.Link>Login</Nav.Link>
                            </LinkContainer>
                          </>
                      )} */}
                      <LinkContainer to="/" onClick={handleEscapeKey}>
                        <Nav.Link>Home</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title="Products" id="basic-nav-dropdown" className="mega-menu fullscreen-menu">
                        <div className="container">
                            {HeaderMenuData.map((header, hIndex)=>(
                               <div className="row" key={hIndex}>
                                    <div className="col-lg-6 product-feature">
                                        <h4>{header.head_1}</h4>
                                        <div className="row">
                                            {header.ProductMenuData.map((productItem, productindex)=>(
                                              <div className="col-lg-6" key={productindex}>
                                                <LinkContainer to={productItem.urls} className={productItem.productClass} onClick={handleEscapeKey}>
                                                  <NavDropdown.Item>
                                                      <span className="logo-part">
                                                        <span className="logo">
                                                          <strong className={productItem.productClass}></strong>
                                                        </span>
                                                        <span className="titles">
                                                          <h5>{productItem.title}</h5>
                                                          <p>{productItem.desc}</p>
                                                        </span>
                                                      </span>
                                                  </NavDropdown.Item>
                                                </LinkContainer>
                                              </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 platform-feature">
                                      <h4>{header.head_2}</h4>
                                      <div className="row">
                                        {header.PlatformFeaturData.map((pData, Pindex)=>(
                                          <div className="col-lg-6" key={Pindex}>
                                            <LinkContainer to={pData.url} onClick={handleEscapeKey}>
                                              <NavDropdown.Item>
                                                  <span className="logo-part">
                                                    <span className="logo">
                                                      <strong className={pData.classNames}></strong>
                                                    </span>
                                                    <span className="titles">
                                                      <h5>{pData.title}</h5>
                                                      <p>{pData.desc}</p>
                                                    </span>
                                                  </span>
                                              </NavDropdown.Item>
                                            </LinkContainer>
                                        </div>
                                        ))}
                                      </div>
                                  </div>
                               </div>
                            ))}
                        </div>
                      </NavDropdown>
                      <LinkContainer to="/customers" onClick={handleEscapeKey}>
                        <Nav.Link>Customers</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title="Company" id="basic-nav-dropdown" className="company-menu">
                        <LinkContainer to="/about" onClick={handleEscapeKey}>
                          <NavDropdown.Item>About Us</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/contact" onClick={handleEscapeKey}>
                          <NavDropdown.Item>Contact Us</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>
                      <NavDropdown title="Resources" id="basic-nav-dropdown" className="resource-menu">
                            {HeaderMenuData.map((headerRe, pfIndex)=>(
                               <Fragment key={pfIndex}>
                                 <NavDropdown title="Platform Features" id="basic-nav-dropdown" className="dropdown-submenu">
                                    {headerRe.PlatformFeaturData.map((prData, prindex)=>(
                                        <Fragment key={prindex}>
                                          <LinkContainer to={prData.url} onClick={handleEscapeKey}>
                                            <NavDropdown.Item>{prData.title}</NavDropdown.Item>
                                          </LinkContainer>
                                        </Fragment>
                                    ))}
                                  </NavDropdown>
                              </Fragment>
                            ))}                              
                          <LinkContainer to="/blog" onClick={handleEscapeKey}>
                            <NavDropdown.Item>Blog </NavDropdown.Item>
                          </LinkContainer>
                      </NavDropdown>
                  </Nav>
                  <form className="ms-auto">
                      <Link
                        to="https://signin.qkrbiz.com"
                        className="btn btn-outline-magenta"
                        href="https://signin.qkrbiz.com"
                        target="_blank"
                      >
                        Login
                      </Link>
                      <Link to="/signup" className="btn btn-magenta" onClick={handleEscapeKey}>Free trial</Link>
                  </form>
              </Navbar.Collapse>
          </Container>
          <div className={isSectionVisible} onClick={handleEscapeKey}></div>
      </Navbar>
    </>
  );
}

export default Header;