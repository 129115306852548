import ImagesUrl from "../../assets/images/Index";
export const HeaderMenuData = [
    {
        id:"1",
        title:"Qkrbiz Apps",
        head_1:"Products",
        head_2:"Platform Features",
        PlatformFeaturData : [
            {
                id:1,
                url:"/views",
                classNames:"pfview",
                imgUrls:ImagesUrl.platformfeatures.PfView,
                title:"Views", 
                headclr:"text-pink",
                desc:"Visualize your work the way you want",
            },
            {
                id:2,
                url:"/features",
                classNames:"pfSpreadsheetFeatures",
                imgUrls:ImagesUrl.platformfeatures.PfpreadsheetFeatures,
                title:"Spreadsheet Features",
                headclr:"text-warning",
                desc:"Comfort of familiar spreadsheet interface",
            },
            {
                id:3,
                url:"/alertnotification",
                classNames:"pfAlertsNotification",
                imgUrls:ImagesUrl.platformfeatures.PfAlertsNotification,
                title:"Alerts & Notifications",
                headclr:"text-danger",
                desc:"Never miss a task or deadline",
            },
            {
                id:4, 
                url:"/workflow",
                classNames:"pfWorkflowAutomation",
                imgUrls:ImagesUrl.platformfeatures.PfAutomationWorkflow,
                title:"Workflow Automation",
                headclr:"text-magenta",
                desc:"Configure workflows as per your policies",
            },
            {
                id:5,
                url:"/customizations",
                classNames:"pfCustomizations",
                imgUrls:ImagesUrl.platformfeatures.PfCustomizations,
                title:"Customizations",
                headclr:"text-magenta",
                desc:"Tailor to fit your needs",
            },
            {
                id:6,
                url:"/documents",
                classNames:"pfDocuments",
                imgUrls:ImagesUrl.platformfeatures.PfDocuments,
                title:"Documents",
                headclr:"text-pink",
                desc:"Integrated Document management",
            },
            {
                id:7,
                url:"/security",
                classNames:"pfSecurity",
                imgUrls:ImagesUrl.platformfeatures.PfSecurity,
                title:"Security",
                headclr:"text-warning",
                desc:"Protecting your data is our obsession",
            },
            {
                id:8,
                url:"/bianalytics",
                classNames:"pfBIAnalytics",
                imgUrls:ImagesUrl.platformfeatures.PfBIAnalytics,
                title:"BI & Analytics",
                headclr:"text-danger",
                desc:"Actionable Insights at your finger tips",
            },
            {
                id:9,
                url:"/boardview",
                classNames:"pfBoards",
                imgUrls:ImagesUrl.platformfeatures.PfBoards,
                title:"Boards",
                headclr:"text-warning",
                desc:"Built your own Kanban boards",
            }
            // {
            //     id:10,
            //     url:"/mobileapp",
            //     classNames:"pfMobileApp",
            //     imgUrls:ImagesUrl.platformfeatures.PfMobileApp,
            //     title:"Mobile App",
            //     headclr:"text-magenta",
            //     desc:"Stay connected in office or outside",
            // },
            // {
            //     id:11,
            //     url:"/listview",
            //     classNames:"pfBoards",
            //     imgUrls:ImagesUrl.platformfeatures.PfBoards,
            //     title:"List View",
            //     headclr:"text-warning",
            //     desc:"Built your List view",
            // } 
        ],
          ProductMenuData : [
            {
                id:1,
                urls:"/hrms-software",
                productClass:"qkrhr",
                title:"QkrHR", 
                desc:"HRMS Software",
                ApsDesc:"Help your team achieve their full potential",
                key: true,
            },
            {
                id:2,
                urls:"/qkrvisit",
                productClass:"qkrvisit",
                title:"Qkrvisit",
                desc:"Field Force Automation",
                ApsDesc:"Field force automation for sales, service and project team",
                key: true,
            },
            {
                id:3,
                urls:"/qkrexpense",
                productClass:"qkrexpense",
                title:"Qkrexpense",
                desc:"Advances, Travel & Expenses",
                ApsDesc:"Advances, Travel & Expenses",
                key: true,
            },
            {
                id:4, 
                urls:"/qkrdesk",
                productClass:"qkrdesk",
                title:"Qkrdesk",
                desc:"Manage Customer Support",
                ApsDesc:"Helpdesk software connecting end users of clients",
                key: true,
            },
            {
                id:5,
                urls:"/qkrsales",
                productClass:"qkrsales",
                title:"Qkrsales",
                desc:"Sales and AR Management",
                ApsDesc:"Order to cash management for government projects",
                key: true,
            },
            {
                id:6,
                urls:"/qkrtenders",
                productClass:"qkrtenders",
                title:"Qkrtenders",
                desc:"Tender Bidding & Deposits",
                ApsDesc:"Manage Tender bidding and Deposits",
                key: true,
            },
            {
                id:7,
                urls:"/qkrteams",
                productClass:"qkrteams",
                title:"Qkrteams",
                desc:"Task Management",
                ApsDesc:"Task Management",
                key: true,
            },
            {
                id:8,
                urls:"/qkrprojects",
                productClass:"qkrprojects",
                title:"Qkrprojects",
                desc:"Collaborative Work Management",
                ApsDesc:"Collaborative Work Management",
                key: true,
            },
            // {
                // id:9,
                // urls:"/hrms-softwar",
                // productClass:"qkrhr",
                // title:"Hrms Softwar", 
                // desc:"HRMS Software",
                // ApsDesc:"Help your team achieve their full potential",
                // key: true,
            // },
        ]
    }  
]
export const HRMenuData = [
    {
        title:"Product",
        desc:"",
        ProductList:[
            {
                id:1,
                urls:"core-hr",
                iconClass:"QkrHR",
                title:"Core HR", 
                desc:"Configure your organization structure and policies the way you run your business",
            },
            {
                id:2,
                urls:"field-force",
                iconClass:"FieldForceAutomation",
                title:"Field Force Automation",
                desc:"Integrate time tracking of your entire field team seamlessly with attendance management",
            },
            {
                id:3,
                urls:"attendance",
                iconClass:"TimeAttendance",
                title:"Time & Attendance",
                desc:"Automate your leave and attendance management",
            },
            {
                id:4, 
                urls:"payroll",
                iconClass:"Payroll",
                title:"Payroll",
                desc:"Payroll processing made as simple as clicking a button",
            },
            {
                id:5,
                urls:"expense",
                iconClass:"Expenses",
                title:"Expenses",
                desc:"Automate expense management and control spending",
            },
            {
                id:6,
                urls:"tasks",
                iconClass:"Tasks",
                title:"Tasks",
                desc:"Organize your work so that everyone is clear of what to do and when",
            },
            {
                id:7,
                urls:"engagement",
                iconClass:"Engagement",
                title:"Employee Engagement",
                desc:"Amplify human interactions"
            }
        ]
    },
  
];
export const SalesMenuData = [
    {
        title:"Product",
        desc:"Automate the entire sales life cycle from receipt of customer PO till payment collection.",
        ProductList:[
            {
                id:1,
                urls:"salesmanagement",
                iconClass:"SalesManagement",
                title:"Sales Management", 
                desc:"With our workflow-based Sales Order management system, streamline your sales process and get complete control of the order execution process! Order management is now simpler than ever thanks to our user-friendly platform.",
            },
            {
                id:2,
                urls:"accountsreceivable",
                iconClass:"AccountsReceivable",
                title:"Accounts Receivable",
                desc:"Make sure that every payment is received on schedule and that you have complete visibility into every receivable at a granular level. No more losses brought on by unrecovered payments!",
            },
            {
                id:3,
                urls:"billingpayment",
                iconClass:"BillingPayment",
                title:"Billing & Payment",
                desc:"Keeping track of customers' complex billing and payment terms can be a daunting task, but it doesn't have to be. With Qkrsales, manage complex customer billing schedules and payments at every step along the way with ease.",
            },
            {
                id:4, 
                urls:"orderexecution",
                iconClass:"OrderExecution",
                title:"Order Execution",
                desc:"Track important deliverables with Milestones, such as delivery, installation, invoicing, and payment, to keep your projects moving forward. Proactively monitor deadlines with warnings to help prevent delays and Liquidated Damages!",
            },
            {
                id:5,
                urls:"invoicing",
                iconClass:"Invoicing",
                title:"Invoicing",
                desc:"Keep on top of billing and never let a due date slip past you with proactive alerts. Streamline your processes by mapping billing terms of every order and track them from start to finish!",
            },
            {
                id:6,
                urls:"tenderdeposit",
                iconClass:"TenderDeposit",
                title:"Management of Tender Deposits",
                desc:"Ensure timely submission and recovery of all deposits such as Security Deposits (SD), Performance Bank Guarantees (PBG), and Earnest Money Deposits (EMD). Make sure that all tender terms are fully complied with meticulous recording from the order stage.",
            },
            {
                id:7,
                urls:"salesperformance",
                iconClass:"SalesPerformance",
                title:"Sales Performance",
                desc:"With real-time performance tracking, your sales team is empowered to maximize their success. Monthly updates keep everyone informed and striving for excellence!",
            },
            {
                id:8,
                urls:"documentmanagement",
                iconClass:"DocumentManagement",
                title:"Document Management",
                desc:"Store and retrieve all of your documents with ease! Accessing all of the documents generated at various phases of order processing is now simple and quick.",
            },
            {
                id:9,
                urls:"businessmanagement",
                iconClass:"BusinessManagement",
                title:"Business Management",
                desc:"Gain greater control of your business with seamless integrations. Streamline processes by integrating Qkrtender, Qkrprojects, QkrHR, Qkrinventory and Qkrexpense into one unified platform.",
            },
        ]
    }

];
// export const SubMenuData = [
//     {
//         Id:1,
//         LogoClass:"qkr-hr",
//         ModuleName:"QkrHR",
//         ModuleUrl:"/qkrhr",
//         ModuleNav : [
//             {
//                 id:1,
//                 title:"Product",
//                 dropdownClass:"mega-menu",
//                 ProductList:[
//                     {
//                         id:1,
//                         urls:"core-hr",
//                         iconClass:"QkrHR",
//                         title:"Core HR", 
//                         desc:"Configure your organization structure and policies the way you run your business",
//                     },
//                     {
//                         id:2,
//                         urls:"field-force",
//                         iconClass:"FieldForceAutomation",
//                         title:"Field Force Automation",
//                         desc:"Integrate time tracking of your entire field team seamlessly with attendance management",
//                     },
//                     {
//                         id:3,
//                         urls:"attendance",
//                         iconClass:"TimeAttendance",
//                         title:"Time & Attendance",
//                         desc:"Automate your leave and attendance management",
//                     },
//                     {
//                         id:4, 
//                         urls:"payroll",
//                         iconClass:"Payroll",
//                         title:"Payroll",
//                         desc:"Payroll processing made as simple as clicking a button",
//                     },
//                     {
//                         id:5,
//                         urls:"expense",
//                         iconClass:"Expenses",
//                         title:"Expenses",
//                         desc:"Automate expense management and control spending",
//                     },
//                     {
//                         id:6,
//                         urls:"tasks",
//                         iconClass:"Tasks",
//                         title:"Tasks",
//                         desc:"Organize your work so that everyone is clear of what to do and when",
//                     },
//                     {
//                         id:7,
//                         urls:"engagement",
//                         iconClass:"Engagement",
//                         title:"Employee Engagement",
//                         desc:"Amplify human interactions"
//                     }
//                 ]
//             },
//         ]
//     }
// ];
