import ImagesUrl from "../../assets/images/Index";
export const TabsScrollData = [
  {
     "id": 1,
     "tabName": "Spreadsheets",
     "tabIcon": "TabIconSpreadSheets",
     "navList": [
       {
         "videoUrl":ImagesUrl.video.TabsSpredsheet,
         "imgUrl": ImagesUrl.TabScroll.Tabspredsheet,
         "title":"Spreadsheets",
         "videoAuto": true,
       }
     ],
     "active": true,
   },
   {
    "id": 2,
     "tabName": "Boards",
     "tabIcon": "TabIconBoards",
     "navList": [
       {
        "videoUrl":"",
         "imgUrl": ImagesUrl.TabScroll.Tabboard,
         "title":"Boards",
         "videoAuto": false,
       }
     ],
     "active": false
   },
   {
    "id": 3,
    "tabName": "Cards",
    "tabIcon": "TabIconCard",
    "navList": [
      {
       "videoUrl":ImagesUrl.video.TabsCards,
        "imgUrl": ImagesUrl.TabScroll.Tabcard,
        "title":"Cards",
         "videoAuto": false,
      }
    ],
    "active": false
  },
  {
    "videoUrl":"",
     "id": 4,
     "tabName": "Pivot Tables",
     "tabIcon": "TabIconPivotTable",
     "navList": [
       {
        "videoUrl":"",
         "imgUrl": ImagesUrl.TabScroll.Tabpivottable,
         "title":"Pivot Tablese",
         "videoAuto": true,
       }
     ],
     "active": false
   },
   {
    "videoUrl":"",
     "id": 5,
     "tabName": "Maps",
     "tabIcon": "TabIconMap",
     "navList": [
       {
         "imgUrl": ImagesUrl.TabScroll.Tabmap,
         "title":"Maps",
         "videoAuto": true,
       }
     ],
     "active": false
   },
   {
    "id": 6,
     "tabName": "Lists",
     "tabIcon": "TabIconList",
     "navList": [
       {
         "imgUrl": ImagesUrl.TabScroll.Tablist,
         "title":"Lists",
         "videoAuto": true,
       }
     ],
     "active": false
   },
   {
     "id": 7,
     "tabName": "Calendars",
     "tabIcon": "TabIconCalendar",
     "navList": [
       {
        "videoUrl":"",
        "imgUrl": ImagesUrl.TabScroll.Tabcalendar,
         "title":"Calendars",
         "videoAuto": true,
       }
     ],
     "active": false
   },
   {
     "id": 8,
     "tabName": "Dashboards",
     "tabIcon": "TabIconDashboard",
     "navList": [
       {
        "videoUrl":"",
        "imgUrl": ImagesUrl.TabScroll.Tabdashboard,
         "title":"Dashboards",
         "videoAuto": true,
       }
     ],
     "active": false
   },
  {
    "id": 9,
    "tabName": "Chats",
    "tabIcon": "TabIconChat",
    "navList": [
      {
        "videoUrl":"",
        "imgUrl": ImagesUrl.TabScroll.Tabchat,
        "title":"Chats",  
        "videoAuto": true,
      }
    ],
    "active": false
  },
   {
    "id": 10,
    "tabName": "Forms",
    "tabIcon": "TabIconForm",
    "navList": [
      {
       "videoUrl":"",
        "imgUrl": ImagesUrl.TabScroll.Tabform,
        "title":"Forms",
        "videoAuto": true,
      }
    ],
    "active": false
  },

]

// export const TabsScrollData = [
//     {
//        "id": 1,
//        "tabName": "Spreadsheets",
//        "tabIcon": "TabIconSpreadSheets",
//        "navList": [
//          {
//            "videoUrl":ImagesUrl.TabScroll.TabVSpreadsheets,
//            "imgUrl": ImagesUrl.TabScroll.Tabspredsheet,
//            "title":"Spreadsheets",
//          }
//        ],
//        "active": true
//      },
//      {
//       "id": 2,
//        "tabName": "Boards",
//        "tabIcon": "TabIconBoards",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Tabboard,
//            "title":"Boards",
//          }
//        ],
//        "active": false
//      },
//      {
//        "id": 3,
//        "tabName": "Forms",
//        "tabIcon": "TabIconForm",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Forms,
//            "title":"Forms",
//          }
//        ],
//        "active": false
//      },
//      {
//        "id": 4,
//        "tabName": "Cards",
//        "tabIcon": "TabIconCard",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Tabcard,
//            "title":"Cards",
//          }
//        ],
//        "active": false
//      },
//      {
//       "videoUrl":"",
//        "id": 5,
//        "tabName": "Pivot Tables",
//        "tabIcon": "TabIconPivotTable",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Tabpivottable,
//            "title":"Pivot Tablese",
//          }
//        ],
//        "active": false
//      },
//      {
//        "id": 6,
//        "tabName": "Calendars",
//        "tabIcon": "TabIconCalendar",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Sprints,
//            "title":"Calendars",
//          }
//        ],
//        "active": false
//      },
//      {
//       "videoUrl":"",
//        "id": 7,
//        "tabName": "Maps",
//        "tabIcon": "TabIconMap",
//        "navList": [
//          {
//            "imgUrl": ImagesUrl.TabScroll.Tabmap,
//            "title":"Maps",
//          }
//        ],
//        "active": false
//      },
//      {
//       "id": 8,
//        "tabName": "Lists",
//        "tabIcon": "TabIconList",
//        "navList": [
//          {
//            "imgUrl": ImagesUrl.TabScroll.Tablist,
//            "title":"Lists",
//          }
//        ],
//        "active": false
//      },
//      {
//        "id": 9,
//        "tabName": "Dashboards",
//        "tabIcon": "TabIconDashboard",
//        "navList": [
//          {
//           "videoUrl":"",
//            "imgUrl": ImagesUrl.TabScroll.Dashboards,
//            "title":"Dashboards",
//          }
//        ],
//        "active": false
//      },
//      {
//       "id": 10,
//       "tabName": "Chats",
//       "tabIcon": "TabIconChat",
//       "navList": [
//         {
//           "videoUrl":"",
//           "imgUrl": ImagesUrl.TabScroll.Chat,
//           "title":"Chats",       
//         }
//       ],
//       "active": false
//     }
   
// ]
